<template>
  <section class="mainText ">
    <b-container fluid="xl">
        <b-row class="maintText__row">
        <b-col cols="12" class="mainText__mainName side-gap">

          <h2 style="mainText__mainName--maintxtx">
            Bántalmazott férfiak - de vajon ki hisz nekik?
          </h2>
          <p class="hashText">- Amikor a férfi az áldozat</p>
        </b-col>
      </b-row>

      <b-row class="maintText__row">
        <b-col cols="12" lg="6" class="side-gap">
          <div class="maintText__txt-content">
            <p class="text">
              Amikor a bántalmazás témaköréről van szó, legtöbbünk fejében általában női áldozatok jelennek meg, akik kétségbeesve próbálnak kitörni férjük, partnerük uralma alól. Azonban ez koránt sincs így: bár a köztudatban kevésbé elterjedt, de annál inkább jelen lévő tendencia az, amikor a nő bántalmazza a férfit. 
  
            </p>
            <p class="text">
                A nők által elkövetett bántalmazás gyakran fel nem ismert, eltitkolt marad. Leggyakoribb formái – akárcsak a férfiak esetében – a fizikai-, verbális- és érzelmi bántalmazás, illetve a szexuális abúzus. 
            </p>
          </div>
        </b-col>
        <b-col cols="12" lg="6" class="side-gap">
            <img src="images/man.png" class="w-100 margin-top-sm-44"/>
        </b-col>
        <b-col cols="12" lg="12" class="side-gap">
            <p class="text">
              <ul>
                <li class="py-3 d-sm-flex">
                  <span class="number">1</span>
                  <p class="text">-	Elsőre hihetetlenül hangozhat, mégis lehetséges, hogy egy nő fizikailag bántalmazzon egy férfit, az erőkülönbség ellenére is. A meglepetésszerű, hirtelen támadás a kiszolgáltatott helyzetben lévő (például: alvó, vezető) férfi ellen nagyon is jellemző. Fizikai abúzus során a leginkább előforduló módszerek közé tartoznak a különféle tárgyak dobálása, összetörése, az ütlegelés, de egészen extrém módszerekkel is lehet találkozni, úgy, mint a mérgezés. </p> 
                </li>
                <li class="py-3 d-sm-flex">
                  <span class="number">2</span>
                  <p class="text">-	Kétségtelen, hogy a fizikai abúzust lehet a leginkább tetten érni, de nem ritka és veszélyes a verbális bántalmazás sem. Ide tartozik a fenyegetés, a követelőzés, a szidás és becsmérlés, sőt, a szarkazmus bizonyos fajtái is. Mivel a bántalmazó fél célja mindig az, hogy a kontroll az ő kezében legyen, kommunikációját ebben az esetben is ez a cél vezérli. A verbális bántalmazás hatása általában nagyon mélyreható, és a bántalmazott félben a félelem, zavarodottság és nagyfokú alkalmatlanság érzését keltheti. </p>
                </li>
                <li class="py-3 d-sm-flex">
                  <span class="number">3</span>
                  <p class="text">-	Ehhez hasonló a motiváció az érzelmi bántalmazás során is: a leggyakoribb jelenség ebben az esetben a gyerekektől való elidegenítés, a szeretet vagy figyelem tudatos visszavonása, illetve a manipuláció. </p>
                </li>
                 <li class="py-3 d-sm-flex">
                  <span class="number">4</span>
                  <p class="text">-	A szexuális abúzus férfiak ellen való elkövetésében kulcsfontosságú eszköze a bántalmazónak a manipuláció. Ide tartozik a gyermekvállalásra való kényszerítés, a megcsalás, a flörtölés. </p>
                </li>
              </ul>
            </p>
            <p>A férfiak bántalmazása tehát egy létező jelenség, amely sokszor azért marad titokban, mert az áldozatok félnek a társadalmi megbélyegzéstől: a szégyen és a félelem megakadályozza a segítségkérésüket, így közös felelősségünk, hogy támogassuk őket baj esetén. A segítség sokszor közelebb van, mint gondolnánk! </p>
        </b-col>
      </b-row>
    </b-container>
  <Articles :isFirst="true"/>
  </section>
</template>
<script>
import Articles from '../components/Articles.vue'

export default {
  components: {
    Articles
  },
  mounted(){

    this.setMetaTags({        
        title: "Bántalmazott férfiak - de vajon ki hisz nekik?", 
        desc: "Bár a köztudatban kevésbé elterjedt, de a nők mellett a férfiak bántalmazása is egy létező jelenség, amely sokszor azért marad titokban, mert az áldozatok félnek a társadalmi megbélyegzéstől.", 
        keywords: null, 
        ogType: 'website', 
        ogTitle: "Bántalmazott férfiak - de vajon ki hisz nekik?",
        ogImage: null, 
        ogImageWidth: null, 
        ogImageHeight: null, 
        ogDesc: "Bár a köztudatban kevésbé elterjedt, de a nők mellett a férfiak bántalmazása is egy létező jelenség, amely sokszor azért marad titokban, mert az áldozatok félnek a társadalmi megbélyegzéstől."        
    })
  }
}
</script>